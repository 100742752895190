<template>
  <div
    style="height: inherit"
    class="advanced-invoices"
  >
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <label for="begin-date">Início em</label>
            <b-form-datepicker
              id="begin-date"
              v-model="beginDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <label for="end-date">Fim em</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
            />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <label for="institution-id">Instituição</label>
                <v-select
                  id="institution-id"
                  v-model="institutionId"
                  name="institution-id"
                  :options="institutionOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  @input="fetchWorkplaces"
                >
                  <span slot="no-options">Nenhuma instituição encontrada.</span>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex w-100">
                  <div class="flex-grow-1">
                    <label for="workplace-id">Unidade</label>
                    <v-select
                      id="workplace-id"
                      v-model="workplaceId"
                      name="workplace-id"
                      :options="workplaceOptions"
                      label="label"
                      item-value="value"
                      item-text="label"
                      :disabled="!institutionId"
                    >
                      <span slot="no-options">Nenhuma unidade encontrado.</span>
                    </v-select>
                  </div>
                  <div class="pt-2 ml-2 d-flex justify-content-end align-content-end">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon mx-1"
                      :disabled="!isValid"
                      @click="loadReport"
                    >
                      Buscar
                      <feather-icon
                        icon="SearchIcon"
                        size="16"
                      />
                    </b-button>

                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- institutions -->
            <div
              v-if="reports.length === 0"
              class="m-5"
            >
              <h4 class="text-center w-100">
                Não há registros
              </h4>
            </div>
            <b-card
              v-if="reports && reports.length > 0"
              no-body
              class="mb-0 card-header-invoice"
            >
              <div
                class="d-flex justify-content-end align-content-center w-100"
              >
                <div class="text-right titles">
                  <div class="d-flex">
                    <div class="d-flex flex-column text-left">
                      <span class="text-muted total">Total</span>
                      <span class="text-muted text-">Instituição</span>
                    </div>
                    <span class="amount align-self-end"> {{ formatCurrencyInt(totalAllDuties(reports)) }}</span>
                  </div>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  size="md"
                  class="btn-icon ml-1 btn-institution"
                  :disabled="!isValidFinishInstitution(reports)"
                  @click="continueInstitutionInvoice()"
                >
                  Finalizar Instituição
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="14"
                  />
                </b-button>
              </div>
            </b-card>
            <app-collapse
              accordion
              type="border"
            >
              <app-collapse-item
                v-for="report in reports"
                :key="report.workplace_id"
                :title="report.workplace_name"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between w-100"
                    :class="{'text-removed': !isValidFinish(report.values)}"
                  >
                    <div class="d-flex">
                      <b-badge
                        pill
                        variant="light-primary"
                        title="Quantidade de plantões"
                      >
                        {{ report.values.length }}
                      </b-badge>
                      <div class="ml-1">
                        <h6>Unidade</h6>
                        <h4>{{ report.workplace_name }}</h4>
                      </div>
                    </div>
                    <div class="text-right">
                      <div>
                        <span class="text-muted">Total</span>
                        <span class="collapse-title"> {{ formatCurrencyInt(totalDuties(report)) }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="w-100 text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    size="md"
                    class="btn-icon btn-unit"
                    :disabled="!isValidFinish(report.values)"
                    @click="continueInvoice(report.workplace_id)"
                  >
                    Finalizar Unidade
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="14"
                    />
                  </b-button>
                </div>
                <b-table
                  ref="refInstitutionListTable"
                  :items="report.values"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  show-empty
                  empty-text="Nenhum registro correspondente encontrado"
                  :sort-desc.sync="isSortDirDesc"
                  class="position-relative"
                >
                  <template #cell(physician_name)="data">
                    <div :class="{'text-removed': data.item.removed}">
                      {{ data.item.physician_name }}
                    </div>
                  </template>
                  <template #cell(physician_cpf)="data">
                    <div :class="{'text-removed': data.item.removed}">
                      {{ data.item.physician_cpf }}
                    </div>
                  </template>
                  <template
                    #cell(anteciped_at)="data"
                  >
                    <div :class="{'text-removed': data.item.removed}">
                      {{ formatDate(new Date(data.item.anteciped_at), 'dd/MM/yy HH:mm') }}
                    </div>
                  </template>
                  <template #cell(start_date)="data">
                    <div :class="{'text-removed': data.item.removed}">
                      {{ formatDate(new Date(data.item.start_date), 'dd/MM/yy HH:mm') }} -
                      {{ formatDate(new Date(data.item.end_date), 'dd/MM/yy HH:mm') }}
                    </div>
                  </template>
                  <template #cell(amount_paid)="data">
                    <div :class="{'text-removed': data.item.removed}">
                      <div>
                          {{ formatCurrencyInt(data.item.amount_paid) }}
                      </div>
                      <div>
                        <b-badge
                          pill
                          class=""
                          :variant="data.item.source === 'physician_duty' ? 'light-success' : 'light-danger'"
                        >
                          {{ data.item.source === 'physician_duty' ? 'Plantão' : 'Serviço' }}
                        </b-badge>
                      </div>
                    </div>
                  </template>
                  <template #cell(company)="{ item }">
                    <b-badge
                      pill
                      :variant="item.person_type === 'PJ' ? 'light-danger' : 'light-success'"
                    >
                      {{ item.person_type || 'PF' }}
                    </b-badge>
                    <div v-if="item.physician_company_cnpj">
                      <strong>{{ item.physician_company_name }}</strong>
                      <div>{{ formatCnpj(item.physician_company_cnpj) }}</div>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      class="btn-remove text-danger"
                      @click="removeInvoice(data.item)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="14"
                      />
                    </b-button>
                  </template>
                </b-table>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-invoice"
      ref="invoiceModal"
      title="Criar fatura"
      hide-footer
      centered
    >
      <div>
        <div class="mb-1">
          <b-alert
            variant="danger"
            :show="alertMultipleWorkplaces"
            class="p-1"
          >
            Deseja realmente gerar fatura por Instituição agrupando mais de uma Unidade?
          </b-alert>
          <label for="deadline-date">Vencimento</label>
          <b-form-datepicker
            id="deadline-date"
            v-model="invoiceData.deadline"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
            label-no-date-selected="Selecione uma data"
          />
          <label for="note">Notas</label>
          <b-form-textarea
            id="note"
            v-model="invoiceData.note"
            rows="3"
            max-rows="6"
          />
        </div>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="loading"
            @click="createInvoice"
          >
            <span class="d-none d-sm-inline">Criar fatura</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  format, startOfMonth, endOfMonth,
} from 'date-fns'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BFormTextarea,
  BFormDatepicker, BButton, BTable, BModal, BBadge, BAlert,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Vue from 'vue'

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import {
  formatDateTime, maskCnpj, maskHiddenCpf, useRouter,
  formatCurrency, formatCurrencyInt, formatCnpj,
} from '@core/utils/utils'
import contractStoreModule from '../contractStoreModule'
import useReport from './useAdvancedPhysicianDutiesInvoice'

export default {
  directives: {
    Ripple,
  },
  components: {
    BAlert,
    BBadge,
    BFormTextarea,
    BModal,
    BTable,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    vSelect,
    BButton,
  },
  methods: {
    formatCnpj,
    maskHiddenCpf,
  },
  setup() {
    const { route, router } = useRouter()
    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    const currency = value => formatter.format(value / 100)
    const formatDate = value => formatDateTime(value)
    const tableColumns = [
      {
        key: 'physician_name',
        label: 'Médico',
      },
      {
        key: 'physician_cpf',
        label: 'CPF',
      },
      { key: 'company', label: 'Empresa' },
      {
        key: 'anteciped_at',
        label: 'Antecipado',
      },
      {
        key: 'start_date',
        label: 'Data',
      },
      {
        key: 'amount_paid',
        label: 'Valor',
      },
      {
        key: 'actions',
        label: '',
      },
    ]
    const reports = ref([])
    const beginDate = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
    const endDate = ref(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
    const dateType = ref('physician_duty')
    const workplaceId = ref('')
    const invoiceModal = ref(null)
    const institutionId = ref('')
    const workplaceOptions = ref([])
    const institutionOptions = ref([])
    const invoiceData = ref({
      institution: null,
      workplace: null,
      start_date: null,
      end_date: null,
      deadline: null,
      note: null,
    })
    const loading = ref(true)
    const institution = ref({
      address: {},
      duties: {
        6: '',
        12: '',
        24: '',
      },
    })
    const currentRouteName = computed(() => route.value.name)

    const getReportParams = () => ({
      begin: beginDate.value,
      end: endDate.value,
      institution: institutionId.value ? institutionId.value.value : '',
      workplace: workplaceId.value ? workplaceId.value.value : '',
    })

    const loadReport = () => {
      loading.value = true
      store.dispatch('app-reports/advancedPhysicianDutiesInvoices', getReportParams())
        .then(response => {
          invoiceData.value = {
            start_date: beginDate.value,
            end_date: endDate.value,
            institution: institutionId.value ? institutionId.value.value : '',
            workplace: workplaceId.value ? workplaceId.value.value : '',
          }
          loading.value = false
          const reps = response.data.data
          // eslint-disable-next-line no-restricted-syntax
          for (const d of reps) {
            // eslint-disable-next-line no-restricted-syntax
            for (const i of d.values) {
              i.removed = false
            }
          }
          reports.value = reps
        })
        .catch(() => {
          loading.value = false
        })
    }

    watch(() => route.value.name, () => {
      workplaceId.value = ''
      institutionId.value = ''
    })

    store.dispatch('app-reports/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })

    const fetchWorkplaces = () => {
      if (institutionId.value) {
        store.dispatch('app-reports/fetchWorkplaceByInstitution', { institution_id: institutionId.value.value })
          .then(response => {
            workplaceOptions.value = response.data.data.map(w => ({
              value: w.id,
              label: w.name,
            }))
          })
      } else {
        workplaceOptions.value = []
        workplaceId.value = null
      }
    }

    const totalDuties = report => report.values
      .filter(d => !d.removed)
      .map(d => d.amount_paid)
      .reduce((partialSum, a) => partialSum + a, 0)

    const totalAllDuties = repts => repts.reduce((partialSum, report) => partialSum + totalDuties(report), 0)
    const removeInvoice = duty => {
      // eslint-disable-next-line no-param-reassign
      duty.removed = duty.removed === undefined ? true : !(duty.removed)
    }

    const continueInstitutionInvoice = () => {
      invoiceData.value.workplace = null
      invoiceModal.value.show()
    }
    const continueInvoice = workplace => {
      invoiceData.value.workplace = workplace
      invoiceModal.value.show()
    }
    const getContracts = () => reports.value
      .filter(r => {
        if (!invoiceData.value.workplace) return true
        return r.workplace_id === invoiceData.value.workplace
      })
      .map(r => r.values)
      .reduce((acc, v) => ([...acc, ...v]), [])
      .filter(d => !d.removed)

    const createInvoice = () => {
      const contracts = getContracts()
        // eslint-disable-next-line no-restricted-syntax
        .map(({ contract_id: id }) => id)

      const payload = {
        institution: invoiceData.value.institution,
        start_date: invoiceData.value.start_date,
        end_date: invoiceData.value.end_date,
        contracts,
        deadline: invoiceData.value.deadline,
        note: invoiceData.value.note,
      }

      store.dispatch('app-reports/generateInvoice', payload)
        .then(async response => {
          invoiceData.value.note = null
          invoiceData.value.deadline = null
          loadReport()
          invoiceModal.value.hide()
          const { invoice_number: code, id } = response.data.data
          const text = `Fatura Nº${code}`
          const resultCreated = await Vue.swal({
            title: 'Fatura criada!',
            text,
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Acessar',
            cancelButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-primary ml-1',
            },
            buttonsStyling: false,
          })
          if (resultCreated.value) {
            router.push({
              name: 'invoice-view',
              params: { id },
            })
          }
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const {
      perPage,
      currentPage,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,
      fetchInstitutions,
      updateInstitution,
      searchQuery,
      sortBy,
      phoneMask,
    } = useReport()

    const isValid = computed(() => !!(institutionId.value))
    const alertMultipleWorkplaces = computed(() => !(invoiceData.value.workplace))

    const isValidFinish = duties => duties
      .filter(d => !d.removed).length > 0

    const isValidFinishInstitution = rts => rts.some(r => r.values.filter(d => !d.removed).length > 0)

    return {
      isValid,
      isValidFinish,
      isValidFinishInstitution,
      removeInvoice,
      totalDuties,
      continueInvoice,
      continueInstitutionInvoice,
      loading,
      createInvoice,
      dateType,
      currency,
      formatDate,
      reports,
      fetchWorkplaces,
      updateInstitution,
      searchQuery,
      fetchInstitutions,
      perfectScrollbarSettings,
      sortBy,
      invoiceData,
      loadReport,
      mqShallShowLeftSidebar,
      beginDate,
      endDate,
      workplaceId,
      institutionId,
      tableColumns,
      perPage,
      currentPage,
      invoiceModal,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,
      institution,
      phoneMask,
      workplaceOptions,
      institutionOptions,
      currentRouteName,
      formatCurrency,
      formatCurrencyInt,
      totalAllDuties,
      alertMultipleWorkplaces,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-contract {
  padding: 0;
  margin-left: 3px;
  margin-top: -7px;
}
.text-muted {
  color: #898993!important;
}
.duty-date, .duty-date .collapse-title {
  font-size: 12px;
}
.physician-group-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
  .collapse-title, .text-right {
    font-size: 12px;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.advanced-invoices {
  .panel-physician {
    background-color: rgb(248, 248, 248);
  }

  label {
    margin-top: 0.2857rem!important;
  }

  .collapse-border .card .card-header {
    padding-top: .5rem;
    padding-bottom: .5rem;
    h4 {
      margin-bottom: 0;
    }
    div.d-flex {
      align-items: center;
    }
  }

  .duty-dates {
    align-items: center;
    h1 {
      color: #898993;
      font-size: 14px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  .physician-label {
    font-size: 12px;
  }

  .btn-remove {
    padding: 8px 14px;
  }
  .text-removed {
    text-decoration: line-through;
    color: #9fa2a7;
    h4, h6 {
      color: #9fa2a7;
    }
  }
  .btn-success {
    padding: 0.615rem 0.636rem!important;
  }
  h6 {
    color: #6e6b7b;
    font-size: 0.85rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  .card-body {
    padding-top: 0!important;
  }

  .btn-unit {
    margin-bottom: 0.5rem!important;
  }

  .card-header-invoice {
    .btn-institution {
      margin-bottom: 4px;
    }
    .titles {
      font-weight: bold;
      .text-muted {
        color: #575756!important;
      }
      .total {
        font-weight: 400;
        font-size: 0.75rem;
      }
      .amount {
        margin-left: 3px;
      }
    }
  }
}
</style>
